import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer/Footer";
import UserNavigation from '../Components/v1/NavBar/Navigaton';
import { createWithoutTokenAPI, fetchWithoutTokenAPI } from "../Api/v1/Util/Api";
import { schemes_fetch_all_url, schemes_find_particular_all_url, schemes_user_scheme_request_submitted_url } from "../Api/APIUrl";
import BestServices from "../Components/v1/Services/BestServices";
import ScheduleMeetBanner from "../Components/v1/Home/ScheduleMeetBanner";
import AppDownload from "../Components/v1/Home/AppDownload";
import FAQ from "../Components/v1/Home/FAQ";
import Newletter from '../Components/v1/Home/Newletter';
import { Modal, Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from "react-redux";

const PlansRequest = () => {

  const state = useSelector((state) => state);
  useEffect(() => {
    window.scroll(0, 0);
    fetchSchemes();
  }, []);

  const [schemes, setSchemes] = useState({});
  const [schemesSpecific, setSchemesSpecific] = useState({});
  const [errors, setErrors] = useState({});
  const [activeSchemeId, setActiveSchemeId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showL2, setShowL2] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    scheme_id: "",
    name: "",
    phone_number: "",
    email_id: "",
  });

  const fetchSchemes = async () => {
    try {
      const response = await fetchWithoutTokenAPI(schemes_fetch_all_url);
      if (response && response.statusCode === 200) {
        const fetchedSchemes = response.data?.schemes || [];

        if (fetchedSchemes.length > 0) {
          const initialSchemeId = fetchedSchemes[0]._id;

          // Set the schemes and active scheme ID
          setSchemes(response.data);
          setActiveSchemeId(initialSchemeId);

          // Set form data with scheme_id, name, and email (if available)
          setFormData({
            ...formData, // Include any existing formData properties first
            scheme_id: initialSchemeId,
            name: state?.auth?.auth?.name || '',
            email_id: state?.auth?.auth?.email || '',
          });


          // Call filterLevel2 with the initial scheme ID
          // filterLevel2(initialSchemeId);
        }
      }
    } catch (error) {
      console.error("Error fetching schemes:", error);
    }
  };

  const filterLevel2 = async (id) => {
    setShowL2(true)
    try {
      const response = await fetchWithoutTokenAPI(schemes_find_particular_all_url + '?_id=' + id);
      if (response && response.statusCode === 200) {
        setSchemesSpecific(response.data);
      }
    } catch (error) {
      console.error(error);
      setShowL2(false)
    }
  };
  const handleSchemeClick = (id) => {
    filterLevel2(id);
    setFormData({ ...formData, scheme_id: id });
    setActiveSchemeId(id); // Update active scheme ID
  };
  const handleOpenModal = () => {
    setErrors({});
    setShowModal(true);
  };

  // Handle form input change
  const handleChange = (e) => {
    setErrors({});
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // Validate form inputs
  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Name is required";
    if (!formData.phone_number || !/^\d{10}$/.test(formData.phone_number)) {
      formErrors.phone_number = "Valid phone number is required (10 digits)";
    }
    if (!formData.email_id || !/\S+@\S+\.\S+/.test(formData.email_id)) {
      formErrors.email_id = "Valid email is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submit
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        // Replace with your API endpoint
        const response = await createWithoutTokenAPI(schemes_user_scheme_request_submitted_url, formData);
        if (response && response.statusCode === 200) {
          toast.success("Consultation request submitted successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
            autoClose: 3000,
          });
          setFormData({})
          handleCloseModal(); // Close modal on success
        } else {
          toast.error("Failed to submit consultation request.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
            autoClose: 5000,
          });
          // alert("Failed to submit consultation request.");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="home-nav" style={{
      backgroundImage: 'url("https://images.unsplash.com/photo-1513530534585-c7b1394c6d51?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cHJvZmVzc2lvbmFsJTIwYmFja2dyb3VuZHxlbnwwfHwwfHx8MA%3D%3D")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      marginTop:'-25px',
      height:'100%',
      height: '100vh', 
     display: 'flex', 
    }}>
      <ToastContainer />
      <Helmet>
        <meta charSet="utf-8" />
        <title>DALMaf Schemes | DALMaf Private Limited</title>
        <meta
          name="description"
          content="Join us at DALMaf Private Limited and explore the opportunities of partnering with us."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <UserNavigation />

      <div className="container mt-5" >
        <div className="row " style={{ margin: "0 auto" }}>

          {/* Schemes List */}
          {!showL2 && (
            <div
              className="col-12"
              style={{
                background: "linear-gradient(135deg, rgba(248, 249, 250, 0.7), rgba(233, 236, 239, 0.7))",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                minHeight:"600px",
                maxHeight:"600px",
                overflow:"auto"
              }}
            >
              <h5 className="mb-4" style={{ fontWeight: "600", color: "#333",fontSize:'27px' }}>
                Our Plans
              </h5>

              {/* Bootstrap grid with gap */}
              <div className="row g-3">
                {schemes && schemes.schemes?.map((data, index) => (
                  <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div
                      style={{
                        width: "100%",
                        height: "120px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontSize: "16px",
                        background: activeSchemeId === data._id ? "linear-gradient(135deg, #dc3545, #ff5f5f)" : "#ffffff",
                        color: activeSchemeId === data._id ? "#fff" : "#333",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        transition: "all 0.3s ease-in-out",
                        textAlign: "center",
                        fontWeight: "500",
                        position: "relative",
                        overflow: "hidden",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.background = "linear-gradient(135deg, #dc3545, #ff5f5f)";
                        e.currentTarget.style.color = "white";
                        e.currentTarget.style.transform = "scale(1.05)";
                        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        if (activeSchemeId !== data._id) {
                          e.currentTarget.style.background = "#ffffff";
                          e.currentTarget.style.color = "#333";
                        }
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
                      }}
                      onClick={() => handleSchemeClick(data._id)}
                    >
                      <span>{data.title}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Scheme Details */}
          {
            showL2 && (
              <div
                className="col-12"
                style={{
                  background: "linear-gradient(135deg, rgba(248, 249, 250, 0.7), rgba(233, 236, 239, 0.7))",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                  minHeight:"600px",
                  maxHeight:"600px",
                  overflow:"auto"
                }}
              >
                {/* Back Button */}
                <button
                  onClick={() => setShowL2(false)}
                  className="btn btn-outline-secondary mb-3"
                  style={{
                    position: "absolute",
                    top: "15px",
                    left: "15px",
                    zIndex: "10"
                  }}
                >
                  ← Back
                </button>

                <h5 className="mb-4 text-center" style={{ fontWeight: "600", color: "#333" }}>
                  Services Covered
                </h5>
                <div className="row">
                  {schemesSpecific.sub_levels?.map((item, index) => (
                    <div key={index} className="col-lg-3 col-md-4 col-sm-12 col-12">
                      {item?.hover_content?.length ? (
                        <OverlayTrigger
                          trigger="hover"
                          placement="top"
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <ol>
                                  {item.hover_content?.map((content, i) => (
                                    <li key={i}>{content}</li>
                                  ))}
                                </ol>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <div
                            style={{
                              padding: "15px",
                              borderRadius: "8px",
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              background: "#ffffff",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              marginBottom: "20px",
                              minHeight: "80px",
                              overflow: "auto",
                              transition: "all 0.3s ease-in-out",
                              cursor: "pointer"
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.background = "linear-gradient(135deg, #dc3545, #ff5f5f)";
                              e.currentTarget.style.color = "white";
                              e.currentTarget.style.transform = "scale(1.05)";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.background = "#ffffff";
                              e.currentTarget.style.color = "black";
                              e.currentTarget.style.transform = "scale(1)";
                            }}
                          >
                            <i className="fa fa-check-circle" style={{ color: "#28a745", fontSize: "18px" }}></i>
                            {item.title}
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <div
                          style={{
                            padding: "15px",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            background: "#ffffff",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            marginBottom: "20px",
                            minHeight: "80px",
                            overflow: "auto",
                            transition: "all 0.3s ease-in-out",
                            cursor: "pointer"
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.background = "linear-gradient(135deg, #dc3545, #ff5f5f)";
                            e.currentTarget.style.color = "white";
                            e.currentTarget.style.transform = "scale(1.05)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.background = "#ffffff";
                            e.currentTarget.style.color = "black";
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        >
                          <i className="fa fa-check-circle" style={{ color: "#28a745", fontSize: "18px" }}></i>
                          {item.title}
                        </div>
                      )}
                    </div>
                  ))}

                  {/* Know More Button */}
                  <div className="col-12 text-center mt-4">
                    <button
                      onClick={handleOpenModal}
                      className="th-btn shadow-none"
                      style={{
                        background: "linear-gradient(135deg, #dc3545, #ff5f5f)",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        transition: "all 0.3s ease-in-out",
                        border: "none"
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.background = "#c82333";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.background = "linear-gradient(135deg, #dc3545, #ff5f5f)";
                      }}
                    >
                      Pricing Details
                    </button>
                  </div>
                </div>
              </div>
            )
          }


        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Free Consultation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                isInvalid={!!errors.phone_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone_number}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email_id"
                value={formData.email_id}
                onChange={handleChange}
                isInvalid={!!errors.email_id}
              />
              <Form.Control.Feedback type="invalid">{errors.email_id}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleCloseModal}
            className="btn">
            Close
          </button>
          <button
            onClick={handleSubmit}
            className="th-btn shadow-none">
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PlansRequest;
