import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format, startOfToday, endOfToday, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subDays, subMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { dashboard_revenue_history_admin_url } from "../../../Api/APIUrl";
import { utils, writeFileXLSX } from 'xlsx';

const RevenueHistoryDashboard = () => {
  const state = useSelector((state) => state);
  const [revenueData, setRevenueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [revenueType, setRevenueType] = useState('all');
  const [error, setError] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(4);

  const filterOptions = [
    { label: "Today", calculateDates: () => [startOfToday(), endOfToday()] },
    { label: "Yesterday", calculateDates: () => [subDays(startOfToday(), 1), subDays(endOfToday(), 1)] },
    { label: "This Week", calculateDates: () => [startOfWeek(new Date()), endOfWeek(new Date())] },
    { label: "Last Week", calculateDates: () => [startOfWeek(subDays(new Date(), 7)), endOfWeek(subDays(new Date(), 7))] },
    { label: "This Month", calculateDates: () => [startOfMonth(new Date()), endOfMonth(new Date())] },
    { label: "Last Month", calculateDates: () => [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
    { label: "Last 3 Months", calculateDates: () => [startOfMonth(subMonths(new Date(), 3)), endOfMonth(new Date())] },
    { label: "Last 6 Months", calculateDates: () => [startOfMonth(subMonths(new Date(), 6)), endOfMonth(new Date())] },
  ];

  const [fromDate, setFromDate] = useState(filterOptions[selectedFilter].calculateDates()[0]);
  const [toDate, setToDate] = useState(filterOptions[selectedFilter].calculateDates()[1]);

  const revenueTypeData = [
    { show: "Package", value: "package" },
    { show: "Custom Package", value: "custom-package" },
    { show: "Service", value: "service" },
    { show: "Auction", value: "auction" },
    { show: "All", value: "all" }
  ];

  const handleFetchHistoryDashboard = async () => {
    if (!fromDate || !toDate) return;

    setLoading(true);
    setError(null);

    try {
      const response = await fetchWithQueryAPI(
        state.auth.auth.token,
        `${dashboard_revenue_history_admin_url}&from_date=${format(fromDate, 'yyyy-MM-dd')}&to_date=${format(toDate, 'yyyy-MM-dd')}&revenue_type=${revenueType}`
      );

      if (response.statusCode === 200) {
        setRevenueData(response.data);
      } else {
        setError(response.message || "Failed to fetch data");
      }
    } catch (error) {
      setError("Error fetching revenue data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchHistoryDashboard();
  }, [fromDate, toDate, revenueType]);

  const handleFilterChange = (index) => {
    setSelectedFilter(index);
    const [from, to] = filterOptions[index].calculateDates();
    setFromDate(from);
    setToDate(to);
  };

  const formatRupee = (value) => `₹${value.toLocaleString('en-IN')}`;
  const formatDate = (date) => format(new Date(date), 'dd-MM-yyyy');

  const exportToExcel = () => {
    const ws = utils.json_to_sheet(revenueData.map(item => ({
      Name: item.name,
      Email: item.email,
      Phone: item.phoneNumber,
      "Revenue Type": revenueTypeData.find(type => type.value === item.revenue_type)?.show || item.revenue_type,
      Title: item.title,
      Price: formatRupee(item.price),
      Date: formatDate(item.createdAt),
    })));

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Revenue Data");
    writeFileXLSX(wb, "RevenueData.xlsx");
  };

  return (
    <div className="container mt-4">
      {loading ? <p>Loading...</p> : error ? <p className="text-danger">{error}</p> : (
        <>
          <div className="row g-3 align-items-center">
            <div className="col-md-3 col-12">
              <label>Filter By</label>
              <select className="form-select" value={selectedFilter} onChange={(e) => handleFilterChange(e.target.value)}>
                {filterOptions.map((option, index) => (
                  <option key={index} value={index}>{option.label}</option>
                ))}
              </select>
            </div>

            <div className="col-md-3 col-12">
              <label>Revenue Type</label>
              <select className="form-select" value={revenueType} onChange={(e) => setRevenueType(e.target.value)}>
                {revenueTypeData.map((option, index) => (
                  <option key={index} value={option.value}>{option.show}</option>
                ))}
              </select>
            </div>

            <div className="col-md-3 col-12">
              <label>From Date</label><br />
              <DatePicker selected={fromDate} onChange={setFromDate} maxDate={new Date()} className="form-control" dateFormat="dd-MM-yyyy" />
            </div>

            <div className="col-md-3 col-12">
              <label>To Date</label><br />
              <DatePicker selected={toDate} onChange={setToDate} maxDate={new Date()} className="form-control" dateFormat="dd-MM-yyyy" />
            </div>
          </div>

          <div className="table-responsive mt-4" style={{ maxWidth: "100%", overflowX: "auto" }}>
            <table className="table table-striped" style={{ minWidth: "1000px" }}>
              <thead>
                <tr>
                  <th style={{ width: "150px" }}>Name</th>
                  <th style={{ width: "200px" }}>Email</th>
                  <th style={{ width: "150px" }}>Phone</th>
                  <th style={{ width: "200px" }}>Revenue Type</th>
                  <th style={{ width: "200px" }}>Title</th>
                  <th style={{ width: "120px" }}>Price</th>
                  <th style={{ width: "180px" }}>Date</th>
                </tr>
              </thead>
              <tbody>
                {revenueData.map((item) => (
                  <tr key={item._id}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{revenueTypeData.find((type) => type.value === item.revenue_type)?.show || item.revenue_type}</td>
                    <td>{item.title}</td>
                    <td>{formatRupee(item.price)}</td>
                    <td>{formatDate(item.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


          <button className="btn btn-primary mt-4" onClick={exportToExcel}>Download Excel</button>
        </>
      )}
    </div>
  );
};

export default RevenueHistoryDashboard;
