import React, { Fragment, useEffect, useRef, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { useSelector } from "react-redux";
import {
  GetAllSubAdmin,
  GetAllSubAdminAndAdmin,
  GetSpecificTask,
  GetUser,
  UpdateTaskAdmin,
  UploadDocument,
} from "../../../Api/Api";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  NonEmptyValidation,
  NonEmptyValidationForDate,
  ExpireDateValidation,
} from "../../../Store/validate";
import CustomModal from "../../../Components/CustomModal";
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export default function MyTaskEdit() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { id } = useParams();

  useEffect(() => {
    GetAllSubadmins();
    getSpecificUser();
    GetMySpecificTsks();
    GetMySpecificTsksRC();
  }, [id])
  const CeoRef = useRef();

  const [myTask, setMyTask] = useState({});
  const [myTaskValidation, setMyTaskValidation] = useState({});

  const setMyTaskValue = (key, value) => {
    setMyTask({ ...myTask, [key]: value });
    if (myTaskValidation[key]) delete myTaskValidation[key];
  };

  const setMyTaskValidationValue = (key, value) => {
    setMyTaskValidation({ ...myTaskValidation, [key]: value });
  };
  const GetMySpecificTsks = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificTask(token, id);
    if (data && data.statusCode === 200) {
      // console.log("data",data.data.assignedTo.name)
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.taskDescription) {
        let blocksFromHtml = htmlToDraft(dummy.taskDescription);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.taskDescription = cmsDescription;

      setMyTask(dummy);
      setLoading(false);
    } else {
      setMyTask({});
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  const [rc, setRC] = useState({});

  const GetMySpecificTsksRC = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificTask(token, id);
    if (data && data.statusCode === 200) {
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.taskDescription) {
        let blocksFromHtml = htmlToDraft(dummy.taskDescription);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      // dummy.taskDescription = cmsDescription;
      // let cmsDescription1 = EditorState.createEmpty();
      // if (dummy.resolutionComment) {
      //   let blocksFromHtml = htmlToDraft(dummy.resolutionComment);
      //   let { contentBlocks, entityMap } = blocksFromHtml;
      //   let contentState = ContentState.createFromBlockArray(
      //     contentBlocks,
      //     entityMap
      //   );
      //   cmsDescription1 = EditorState.createWithContent(contentState);
      // }
      // dummy.resolutionComment = cmsDescription1;
      // //console.log(dummy);
      setRC(dummy);
      setLoading(false);
    } else {
      setRC([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setCommentsValue("comments", html);
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    setLoading(true);
    // const role = "subadmin";
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      setLoading(false);
    } else {
      setAllSubadmin([]);
      setLoading(false);

      //console.log("Error while getting all subadmins");
    }
  };

  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange1 = (state) => {
    setEditorState1(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));

      setMyTaskValue("resolutionComment", html);
    }
  };
  const [myTaskEditComment, setMyTaskEditComment] = useState("");
  const [commentsValidation, setCommentsValidation] = useState({});

  const setCommentsValue = (key, value) => {
    setMyTaskEditComment({ ...myTaskEditComment, [key]: value });

    if (commentsValidation[key]) delete commentsValidation[key];
  };

  const setCommentsLeadValidationValue = (key, value) => {
    setCommentsValidation({
      ...commentsValidation,

      [key]: value,
    });
  };

  // const [comment, setComment] = useState("");
  const handleSubmitComment = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.comments = NonEmptyValidation(myTaskEditComment?.comments);
    setCommentsValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        _id: id,
        comments: myTaskEditComment?.comments,
      };
      // 6441de0da3fe015898ab275f
      const token = state.auth.auth.token;
      //console.log(Payload);
      const response = await UpdateTaskAdmin(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        GetMySpecificTsks();
        window.location.reload();
        setMyTaskEditComment("");
        e.target.reset();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while update user details");
    }
  };
  let assignName = allSubadmin?.filter((e) => {
    return e._id === myTask?.assignedTo;
  });

  const uploadCeoPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        const data = response.data;
        if (myTask?.fileUpload?.length) {
          let scheduleData = myTask?.fileUpload;
          scheduleData.push(data);
          setMyTaskValue("fileUpload", scheduleData);
        } else {
          setMyTaskValue("fileUpload", [data]);
        }
        // setMyTaskValue("fileUpload", response.data);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };
  const [open, setOpen] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.taskType = NonEmptyValidation(myTask?.taskType);
    validate.taskName = NonEmptyValidation(myTask?.taskName);
    // validate.taskDescription = NonEmptyValidation(myTask?.taskDescription);
    validate.startDate = NonEmptyValidationForDate(myTask?.startDate);
    validate.deadlineDate = ExpireDateValidation(
      myTask?.startDate,
      myTask?.deadlineDate
    );
    // validate.assignedTo = NonEmptyValidation(myTask?.assignedTo);

    setMyTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let html;
      let Payload;
      let isNotEmpty =
        myTask.taskDescription &&
        myTask.taskDescription.getCurrentContent().hasText();
      if (isNotEmpty) {
        html = draftToHtml(
          convertToRaw(myTask.taskDescription.getCurrentContent())
        );
        Payload = {
          _id: myTask._id,
          taskType: myTask.taskType,
          taskName: myTask.taskName,
          taskDescription: html,
          startDate: myTask.startDate,
          deadlineDate: myTask.deadlineDate,
          assignedTo: myTask.assignedTo._id,
          fileUpload: myTask.fileUpload,
          resolutionComment: myTask.resolutionComment,
          creator_id: myTask?.creator_id?._id,
          status: myTask.status,
        };
      }

      const token = state?.auth?.auth?.token;
      const response = await UpdateTaskAdmin(Payload, token);
      if (response && response.statusCode === 200) {
        if (myTask?.status === "Completed") {
        } else {
          showToastSuccessMsg(response.message);
        }

        if (myTask?.status === "Completed") {
          setOpen(true);
        }
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const [specificUser, setSpecificUser] = useState([]);

  const getSpecificUser = async () => {
    const token = state?.auth?.auth?.token;
    // const id = state?.auth?.auth?._id;
    const data = await GetUser(token, myTask?.assignedTo?._id);
    if (data && data.statusCode === 200) {
      setSpecificUser(data?.data);
    } else {
      setSpecificUser([]);
      //console.log("Error while getting user details");
    }
  };

  const handleSubmitUpdateComplete = async (e, value, id) => {
    e.preventDefault();
    if(!value.length){
      alert("Resolution Comment can't be empty")
      return null;
    }
    const Payload = {
      _id: id,
      resolutionComment: value,
    };
    const token = state?.auth?.auth?.token;

    const response = await UpdateTaskAdmin(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setOpen(false);
      window.location.reload();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const [reassign, setReassign] = useState(true);

  function URLToFileName(url) {
    // Decode the URL to get the actual filename
    const decodedUrl = decodeURIComponent(url);
    // Split the decoded URL using '/' to get the segments of the URL
    const urlSegments = decodedUrl.split("/");
    // The last segment should be the filename
    const filename = urlSegments[urlSegments.length - 1];
    return filename;
  }
  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/MyTask");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Update Task</h6>
            </div>
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label className="form-label">Task Name</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the Task Name"
                          aria-invalid={Boolean(
                            myTaskValidation?.taskType?.status === false
                          )}
                          value={myTask?.taskType}
                          onChange={(e) => {
                            setMyTaskValue("taskType", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskValidationValue(
                              "taskType",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          disabled={state?.auth?.auth?.role === "subadmin"}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myTaskValidation?.taskType?.message
                            ? `Task name ${myTaskValidation?.taskType?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label for="services" className="form-label">
                          Service Type
                        </label>
                        <select
                          className="form-control"
                          value={myTask?.taskName}
                          onChange={(e) => {
                            setMyTaskValue("taskName", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskValidationValue(
                              "taskName",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          disabled={state?.auth?.auth?.role === "subadmin"}
                        >
                          <option value="audit">Audit</option>
                          <option value="legal">Legal</option>
                          <option value="manPower">Man Power</option>
                          <option value="auction">Auction</option>
                          <option value="buySell">Buy/Sell</option>
                          <option value="In House">In House</option>
                        </select>
                        <small className="text-danger">
                          {myTaskValidation?.taskName?.message
                            ? `Service type ${myTaskValidation?.taskName?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label className="form-label">Task Description</label>
                        <div
                          className="form-control form-control-lg"
                          style={{ height: "250px" }}
                        >
                          <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState={myTask?.taskDescription}
                            onEditorStateChange={(e) =>
                              setMyTaskValue("taskDescription", e)
                            }
                            disabled={state?.auth?.auth?.role === "subadmin"}
                          />
                        </div>
                        {/* <textarea
                                rows={6}
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  myTaskValidation?.taskDescription?.status ===
                                    false
                                )}
                                value={myTask?.taskDescription}
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "taskDescription",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "taskDescription",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                        <small classtaskDescription="text-danger">
                          {myTaskValidation?.taskDescription?.message
                            ? `Task description ${myTaskValidation?.taskDescription?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Start Date</label>
                        <input
                          type="date"
                          className="form-control form-control-lg"
                          placeholder="select the  start date"
                          aria-invalid={Boolean(
                            myTaskValidation?.startDate?.status === false
                          )}
                          value={moment(myTask?.startDate).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setMyTaskValue("startDate", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskValidationValue(
                              "startDate",
                              NonEmptyValidationForDate(e.target.value)
                            );
                          }}
                          disabled
                          autoComplete="off"
                        />
                        <small classstartDate="text-danger">
                          {myTaskValidation?.startDate?.message
                            ? `Start date ${myTaskValidation?.startDate?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Deadline Date</label>
                        <input
                          type="date"
                          className="form-control form-control-lg"
                          placeholder="select the Deadline Date"
                          aria-invalid={Boolean(
                            myTaskValidation?.deadlineDate?.status === false
                          )}
                          value={moment(myTask?.deadlineDate).format(
                            "YYYY-MM-DD"
                          )}
                          onChange={(e) => {
                            setMyTaskValue("deadlineDate", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskValidationValue(
                              "deadlineDate",
                              ExpireDateValidation(
                                myTask?.startDate,
                                e.target.value
                              )
                            );
                          }}
                          disabled
                          autoComplete="off"
                        />
                        <small classdeadlineDate="text-danger">
                          {myTaskValidation?.deadlineDate?.message
                            ? `Deadline date ${myTaskValidation?.deadlineDate?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label ">Assigned To</label>
                        {
                          state?.auth?.auth?.role === "subadmin"?
                          <input type="text" 
                          className="form-control form-control-lg"
                          value={myTask?.assignedTo?.name} disabled/>

                          :
                          <>
                            <select
                            className="form-control form-control-lg"
                            defaultValue={myTask?.assignedTo?.name}
                            onChange={(e) => {
                              let name = allSubadmin?.filter(
                                (s) => s.name === e.target.value
                              );
                              let nameId = name?.map((n) => {
                                return n._id;
                              });
                              setMyTaskValue("assignedTo", nameId?.toString());
                            }}
                            onBlur={(e) => {
                              let name = allSubadmin?.filter(
                                (s) => s.name === e.target.value
                              );
                              let nameId = name?.map((n) => {
                                return n._id;
                              });
                              setMyTaskValidationValue(
                                "assignedTo",
                                NonEmptyValidation(nameId?.toString())
                              );
                            }}
                            disabled={state?.auth?.auth?.role === "subadmin"}
                          >
                          
                            {allSubadmin?.map((e) => (
                              <option value={e.name}>{e.name}</option>
                            ))}
                            </select>
                            <small classdeadlineDate="text-danger">
                              {myTaskValidation?.assignedTo?.message
                                ? `Member name ${myTaskValidation?.assignedTo?.message}`
                                : ""}
                            </small>
                          </>
                        }
                      </div>
                    </div>
                    <div className="col-lg-6 my">
                      <div className="mb-3">
                        <label htmlFor="fileUpload" className="form-label">
                          Select File
                        </label>

                        <input
                          type="file"
                          className="form-control"
                          id="file"
                          ref={CeoRef}
                          onChange={(e) => {
                            uploadCeoPic(e);
                          }}
                        />
                        {myTask?.fileUpload?.map((e, index) => {
                          const url = e;
                          // Decode the URL to get the actual filename
                          const decodedUrl = decodeURIComponent(url);
                          // Split the decoded URL using '/' to get the segments of the URL
                          const urlSegments = decodedUrl.split("/");
                          // The last segment should be the filename
                          const filename = urlSegments[urlSegments.length - 1];

                          return (
                            <Fragment key={e}>
                              <a
                                style={{ color: "black" }}
                                href={e}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {index + 1} {filename}
                              </a>
                              <br />
                            </Fragment>
                          );
                        })}
                        {/* <a
                          className=""
                          href={myTask?.fileUpload}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {URLToFileName(myTask?.fileUpload)}
                        </a> */}
                        {/* <button
                              className="btn btn-primary mt-2"
                              onClick={() => CeoRef.current()}
                            >
                              Upload
                            </button> */}
                      </div>
                    </div>
                    <div className="col-lg-6 my">
                      <div className="mb-3">
                        <label for="services" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-control"
                          value={myTask?.status}
                          onChange={(e) => {
                            setMyTaskValue("status", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskValidationValue(
                              "status",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                        >
                          <option value="Todo">To Do</option>
                          <option value="Inprogress">In Progress</option>
                          <option value="Completed">Completed</option>
                          <option value="Hold">Hold</option>
                        </select>
                        <small className="text-danger">
                          {myTaskValidation?.status?.message
                            ? `Status ${myTaskValidation?.status?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    {rc?.resolutionComment && rc?.status === "Completed" ? (
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="form-label">
                            Resolution Comment
                          </label>
                          <div
                            className="form-control form-control-lg"
                            style={{
                              height: "250px",
                              border: "4px solid maroon",
                            }}
                          >
                            {/* <Editor
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName="toolbar-class"
                              editorState={rc?.resolutionComment}
                            /> */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: rc?.resolutionComment,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-lg-12">
                      {/* <button
                        type="button"
                        className="btn white-btn me-2 "
                        onClick={() => {
                          setMyTask({});
                        }}
                      >
                        Cancel
                      </button> */}
                      <button
                        type="button"
                        className="btn login-btn ms-2 "
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                    <>
                      {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                      {myTask?.commentsTo?.map((e, i) => (
                        <>
                          <div
                            key={i}
                            className="send-pro-bg mt-4"
                            style={{
                              border: "1px solid black",
                              borderRadius: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex   align-items-center">
                                <div className="flex-shrink-0 ">
                                  {e?.profileImage ? (
                                    <img
                                      className="admin-pro-img "
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={e?.profileImage}
                                      alt="..."
                                    />
                                  ) : (
                                    <span className="material-symbols-rounded filled-icon dash-icon">
                                      person
                                    </span>
                                  )}
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="fw-bold">{e?.name}</h6>
                                  {e.Designation ? (
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                      className="mb-0"
                                    >
                                      {e.Designation}
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                      className="mb-0"
                                    >
                                      {e.role}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Asked {moment(e?.createdAt).fromNow()}
                                </p>
                              </div>
                            </div>
                            <p
                              className="mb-0 mt-3 pl-send-pro-bg"
                              dangerouslySetInnerHTML={{
                                __html: e.comments,
                              }}
                            ></p>
                          </div>
                        </>
                      ))}
                      {state?.auth?.auth?.role === "subadmin" ||
                      state?.auth?.auth?.role === "superadmin" ||
                      state?.auth?.auth?.role === "admin" ? (
                        <>
                          <div className="input-group flex-nowrap  my-3">
                            <div
                              className="form-control form-control-lg"
                              style={{ height: "250px" }}
                            >
                              <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={editorState}
                                onEditorStateChange={(e) =>
                                  handleEditorChange(e)
                                }
                              />
                            </div>
                            {/* <input
                              style={{
                                border: "1px solid #c9c8c8",
                                backgroundColor: "transparent",
                                borderRight: "0px",
                              }}
                              type="text"
                              className="form-control"
                              placeholder="Your Message"
                              aria-invalid={Boolean(
                                commentsValidation?.comments?.status === false
                              )}
                              defaultValue={myTaskEditComment?.comments}
                              onChange={(e) => {
                                setCommentsValue("comments", e.target.value);
                              }}
                              onBlur={(e) => {
                                setCommentsLeadValidationValue(
                                  "comments",

                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                            /> */}
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                              style={{
                                border: "1px solid #c9c8c8",
                                backgroundColor: "transparent",
                                borderLeft: "0px",
                              }}
                            >
                              <button
                                className="btn py-1  login-btn"
                                type="button"
                                id="button-addon2"
                                onClick={(e) => {
                                  handleSubmitComment(e);
                                }}
                              >
                                Send
                                <i className="fa-solid fa-paper-plane ms-2"></i>
                              </button>
                            </span>
                          </div>
                          <small className="text-danger">
                            {commentsValidation?.comments?.message
                              ? `comments ${commentsValidation?.comments?.message}`
                              : ""}
                          </small>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          open={open}
          onClickOutside={() => {
            setOpen(false);
          }}
        >
          <div className="modal-content w-50 m-auto">
            <div className="modal-body p-0 ">
              <div className="container">
                {/* <span
                  className="material-symbols-rounded filled-icon close-icon"
                  style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  close
                </span> */}
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className=" my-5 px-3">
                          <div className="row justify-content-center mt-2">
                            <h2 className="form-label">Resolution Comments</h2>
                            <div
                              className="form-control form-control-lg"
                              style={{ height: "250px" }}
                            > 
                              <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={editorState1}
                                onEditorStateChange={(e) =>
                                  handleEditorChange1(e)
                                }
                              />
                            </div>
                            <div className="col-6 text-center">
                              <button
                                className="btn btn-admin mt-3"
                                onClick={(e) =>
                                  handleSubmitUpdateComplete(
                                    e,
                                    myTask?.resolutionComment,
                                    myTask._id
                                  )
                                }
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
