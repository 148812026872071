import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { fetchWithNewQueryAPI } from "../Api/v1/Util/Api";
import { payment_service_v1_entrepreneur_request_payment_status_check_url, payment_service_v1_scheme_request_payment_status_check_url } from "../Api/APIUrl";
import UserNavigation from "../Components/v1/NavBar/Navigaton";
import { Spinner } from "react-bootstrap";

function PlanPaymentValidation() {
    const { reference_id, type } = useParams();
    const state = useSelector((state) => state);
    const token = state.auth.auth.token;

    const [paymentStatus, setPaymentStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            let url;
            if (type && type === "ba-payment") {
                url = `${payment_service_v1_entrepreneur_request_payment_status_check_url}?reference_id=${reference_id}`;
            } else if (type && type === "ecorner-payment") {
                url = `${payment_service_v1_entrepreneur_request_payment_status_check_url}?reference_id=${reference_id}`;
            } else if (type && type === "franchise-payment") {
                url = `${payment_service_v1_entrepreneur_request_payment_status_check_url}?reference_id=${reference_id}`;
            } else {
                url = `${payment_service_v1_scheme_request_payment_status_check_url}?reference_id=${reference_id}`;
            }
            const response = await fetchWithNewQueryAPI(
                token, url
            );


            if (response && response.statusCode === 200) {
                setPaymentStatus(response?.data?.payment_status);
            } else {
                setError("Failed to retrieve payment status.");
            }
        } catch (err) {
            setError("Something went wrong. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Payment Confirmation | DALMaf Private Limited</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Payment Confirmation" />
                <meta name="keywords" content="Payment Confirmation, Order Status" />
                <meta name="author" content="DALMaf" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <UserNavigation />

            <div className="container d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "80vh" }}>
                {loading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : error ? (
                    <div className="alert alert-danger text-center">
                        <h4>Payment Status</h4>
                        <p>{error}</p>
                    </div>
                ) : paymentStatus === "success" ? (
                    <div className="alert alert-success text-center">
                        <h4>Payment Successful</h4>
                        <p>Thank you! Your payment has been successfully processed.</p>
                        {
                            (type && (type === "ba-payment" || type === "ecorner-payment" || type === "franchise-payment")) ?
                                <a href="https://dalmaf.com/Dashboard" className="btn btn-primary mt-3">Go to Dashboard</a> :
                                <a href="https://dalmaf.com/my-plans" className="btn btn-primary mt-3">Go to Dashboard</a>

                        }

                    </div>
                ) : (
                    <div className="alert alert-danger text-center">
                        <h4>Payment Failed</h4>
                        <p>We couldn't process your payment. Please try again or contact support.</p>

                        <a
                            href={{
                                "ba-payment": "https://dalmaf.com/my-ba-plans",
                                "ecorner-payment": "https://dalmaf.com/my-dpl-ecorner-plans",
                                "franchise-payment": "https://dalmaf.com/my-franchise-plans"
                            }[type] || "https://dalmaf.com/my-plans"}
                            className="btn btn-warning mt-3"
                        >
                            Retry Payment
                        </a>
                    </div>

                )}
            </div>
        </div>
    );
}

export default PlanPaymentValidation;
