import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AdminNavbar from "../../../Admin/Component/Navbar/AdminNavbar";
import MyFranchisePlansListComponent from "../../../Components/v1/Schemes/MyFranchisePlansListComponent";

export default function MyFranchisePlansList() {
  const [value, setValue] = React.useState('active');


  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main" style={{marginTop:'20px'}}>
        {/* <div className="main"> */}
          <MyFranchisePlansListComponent/>
        </div>
    </div>
  );
}