import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AdminNavbar from "../../../Admin/Component/Navbar/AdminNavbar";
import SchemesRequestListComponent from "../../../Components/v1/Schemes/SchemesRequestListComponent";
import PlansRequestListComponent from "../../../Components/v1/Schemes/PlansRequestListComponent";
import MyPlansRequestListComponent from "../../../Components/v1/Schemes/MyPlansRequestListComponent";

export default function MyPlansList() {
  const [value, setValue] = React.useState('active');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const filter = [
    { key: "active", show: "Active" },
    { key: "pending", show: "Yet to Pay" },
    { key: "inactive", show: "Expired" },
  ];
  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      {/* <Box> */}
      <div className="main" style={{marginTop:'20px'}}>
        {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Schedule Meeting Details"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#dc3545", // Highlight active tab with a red underline
            },
          }}
          textColor="inherit"
          // centered
        >
          {filter?.map((item) => (
            <Tab
              key={item.key}
              label={item.show}
              value={item.key}
              sx={{
                color: value === item.key ? "#dc3545" : "#000", // Active tab color change
                fontWeight: value === item.key ? "bold" : "normal",
                backgroundColor: value === item.key ? "#fff2f2" : "transparent", // Light background for active tab
                borderRadius: "5px", // Rounded edges for tabs
                padding: "10px 20px", // Better padding for tabs
                transition: "0.3s ease", // Smooth transition
                "&:hover": {
                  color: "#dc3545", // Red color on hover for non-active tabs
                  backgroundColor: "#f9f9f9", // Light gray on hover
                },
              }}
            />
          ))}
        </Tabs> */}

        {/* <div className="main"> */}
          <MyPlansRequestListComponent
            // statusTypeProps={value}
            // labelProps={filter?.find((item) => item.key === value)?.show} // Pass the label as a prop
          />
        </div>
      {/* </Box> */}

    </div>
  );
}