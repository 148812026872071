import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Card, CardContent, Typography, Button, Container, Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { createAPIV2, fetchWithNewQueryAPI } from "../../../Api/v1/Util/Api";
import { lead_service_v1_user_scheme_request_find_all_url } from "../../../Api/APIUrl";
import { useNavigate } from "react-router-dom";
import { payment_service_url } from "../../../Api/BaseUrl";
import { showErrorMsg } from "../../../Store/util";
import dayjs from "dayjs";




export default function MyPlansRequestListComponent() {
  const [data, setData] = useState([]);
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const token = state.auth.auth.token;

  const fetchData = async () => {
    const response = await fetchWithNewQueryAPI(token, lead_service_v1_user_scheme_request_find_all_url + "?user_id=" + state?.auth?.auth?._id);
    if (response && response.statusCode === 200) {
      if (response.data) {
        setData(response?.data?.scheme_request);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleButtonClick = async (id) => {
    let payload = {
      reference_id: id,
      return_url: `https://dalmaf.com/paymentvalidation/plan-payment/${id}`
    }
    let formData = new FormData();
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        formData.append(key, payload[key]);
      }
    }
    let response = await createAPIV2(token, payment_service_url + "v1/scheme-request/payment/initiate", formData)
    // Add your logic here, e.g., API call, navigation, etc.
    if (response.statusCode === 200) {
      window.location.href = response?.data?.url

    } else {
      showErrorMsg('Failed to create payment link, Try Again')
    }
  };


  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          {data.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item._id}>
              <Card sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
                <CardContent>
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {item?.scheme_title}
                  </Typography>

                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="body1" fontWeight="bold">Amount:</Typography>
                    <Typography variant="body1" color="textSecondary">
                      {new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(item?.monthly_amount)}
                    </Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="body1" fontWeight="bold">GST Amount:</Typography>
                    <Typography variant="body1" color="textSecondary">
                      {new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(item?.gst_amount)}
                    </Typography>
                  </Box>
                  {(item?.renew_button_shown || item?.status === "pending") ? (
                    <>
                      <Box display="flex" justifyContent="space-between" mb={1}>
                        <Typography variant="body1" fontWeight="bold">Validity:</Typography>
                        <Typography variant="body1" color="textSecondary">
                          {item?.package_type === "monthly"
                            ? "1 month"
                            : item?.package_type === "quarterly"
                              ? "3 months"
                              : item?.package_type === "halfyearly"
                                ? "6 months"
                                : item?.package_type === "yearly"
                                  ? "1 year"
                                  : "N/A"}
                        </Typography>

                        {/* <Typography variant="body1" color="textSecondary">1 Days</Typography> */}
                      </Box>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleButtonClick(item.payment_reference_id || item._id)}
                        sx={{ mt: 2, width: "100%" }}
                      >
                        Pay Now {new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(item.monthly_amount + item.gst_amount)}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Box display="flex" justifyContent="space-between" mb={1}>
                        <Typography variant="body1" fontWeight="bold">Expiry:</Typography>
                        <Typography variant="body1" color="textSecondary">
                          {dayjs(item?.expiry_date).format("DD MMM YYYY")}
                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        sx={{ mt: 2, width: "100%" }}
                      >
                        Pay ({new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(item?.monthly_amount + item?.gst_amount)})
                        after {dayjs(item?.expiry_date).diff(dayjs(), "day")} days
                      </Button>
                    </>
                  )}

                </CardContent>


              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <ToastContainer />
    </>
  );
}
