import React, { useState, useEffect } from 'react';
import PreLoader from "./PreLoader";
import Footer from "../Components/Footer/Footer";
import HomePage from "../Components/Home/HomePage";
import Navigation from "../Components/Navbar/Navigation";
import {Helmet} from "react-helmet";
import UserNavigation from '../Components/v1/NavBar/Navigaton';
import HomeBanner from '../Components/v1/Home/HomeBanner';
import BestServices from '../Components/v1/Services/BestServices';
import ServicesCount from '../Components/v1/Services/ServicesCount';
import Testimonial from '../Components/v1/Home/Testimonial';
import AboutUs from '../Components/v1/Home/AboutUs';
import AppDownload from '../Components/v1/Home/AppDownload';
import Newletter from '../Components/v1/Home/Newletter';
import FAQ from '../Components/v1/Home/FAQ';
import ScheduleMeetBanner from '../Components/v1/Home/ScheduleMeetBanner';
import UserNavigationV2 from '../Components/v1/NavBar/UserNavigationV2';
import AboutExperince from '../Components/v1/Home/AboutExperince';
import CustomerLogo from '../Components/v1/Services/CustomerLogo';

export default function Home() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [containerLoaded, setContainerLoaded] = useState(false);
  const [preloaderVisible, setPreloaderVisible] = useState(true);

  const [megaMenu, setMegaMenu] = useState(false);
  const [menuId, setMenuId] = useState("");
  const [menuTitle, setMenuTitle] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setContainerLoaded(true);
    }, 5000); // Increase the timeout to 5000 milliseconds (5 seconds)

    if (containerLoaded) {
      setTimeout(() => {
        setPreloaderVisible(false);
      }, 50);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [containerLoaded]);

  const megaMenuInputFun = (value) =>{
    setMegaMenu(value);
  }
  const mainMenuIdInputFun = (value) =>{
    setMenuId(value)
  }
  const mainTitleInputFun = (value) =>{
    setMenuTitle(value)
  }
  
  return (
    <div className="home-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="At Dalmaf, We understand the challenges and complexities that businesses experience in today's dynamic and highly competitive marketplace. As a result, we are here to deliver a wide range of Expert Assistance Tailored to your Success. We Provide Comprehensive Services in the areas of Legal, Audit, Human Resources, Bank Auctions, Buying/Selling, and Marketing Assistance resulting from our extensive experience and dedication to excellence." />
        <meta name="keywords" content="At Dalmaf, We understand the challenges and complexities that businesses experience in today's dynamic and highly competitive marketplace. As a result, we are here to deliver a wide range of Expert Assistance Tailored to your Success. We Provide Comprehensive Services in the areas of Legal, Audit, Human Resources, Bank Auctions, Buying/Selling, and Marketing Assistance resulting from our extensive experience and dedication to excellence." />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      {/* <PreLoader/> */}
      {/* {preloaderVisible && (
        <div >
           <PreLoader/>
        </div>
      )} */}
      <div id="container" className={containerLoaded ? 'loaded' : ''}>
      {/* <Navigation /> */}
      <UserNavigation/>
      {/* <UserNavigationV2/> */}
      <HomeBanner />
      <CustomerLogo/>
      <ServicesCount megaMenuInputProps={megaMenuInputFun} mainMenuIdInputProps={mainMenuIdInputFun} mainMenuTitleProps={mainTitleInputFun}/>
      <BestServices/>
      <AboutUs/>
      <AboutExperince/>
      <ScheduleMeetBanner/>
      {/* <Testimonial/> */}
      <AppDownload/>
      <Newletter/>
      {/* <FAQ/> */}
      
      {/* <HomeBanner /> */}
      {/* <HomePage /> */}
      <Footer />
      </div>
      
    </div>
  );
}
