import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Card, CardContent, Typography, Button, Container,Box } from "@mui/material";
import { createAPIV2 } from "../../../Api/v1/Util/Api";
import { payment_service_url } from "../../../Api/BaseUrl";
import { showErrorMsg } from "../../../Store/util";
import { GetUser } from "../../../Api/Api";
import dayjs from "dayjs";

export default function PlansRequestListComponent({ statusTypeProps }) {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const state = useSelector((state) => state);
  const token = state.auth.auth.token;

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetUser(token, state?.auth?.auth?._id);
      if (response?.statusCode === 200 && response?.data) {
        setUserData(response.data);
        setData([response.data.entrepreneur_monthly_plan_details]);
      }
    };
    setData([]);
    fetchData();
  }, [statusTypeProps]);

  const handleButtonClick = async (id) => {
    let payload = {
      reference_id: userData?.entrepreneur_reference_id,
      return_url: `https://dalmaf.com/paymentvalidation/plan-payment/${userData?.entrepreneur_reference_id}/ba-payment`,
    };
    let formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    let response = await createAPIV2(token, payment_service_url + "v1/entrepreneur-request/payment/initiate", formData);
    if (response.statusCode === 200) {
      window.location.href = response?.data?.url;
    } else {
      showErrorMsg("Failed to create payment link, Try Again");
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={3}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item._id}>
            <Card sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {item.title}
                </Typography>

                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Typography variant="body1" fontWeight="bold">Monthly Amount:</Typography>
                  <Typography variant="body1" color="textSecondary">
                    {new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(item.monthly_amount)}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Typography variant="body1" fontWeight="bold">GST Amount:</Typography>
                  <Typography variant="body1" color="textSecondary">
                    {new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(item.gst_amount)}
                  </Typography>
                </Box>

                {userData.is_entrepreneur_account_active ? (
                  <>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body1" fontWeight="bold">Expiry:</Typography>
                      <Typography variant="body1" color="textSecondary">
                        {dayjs(userData?.entrepreneur_monthly_plan_expiry_date).format("DD MMM YYYY")}
                      </Typography>
                    </Box>

                    <Button
                      variant="contained"
                      color="primary"
                      disabled
                      sx={{ mt: 2, width: "100%" }}
                    >
                      Pay ({new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(item.monthly_amount + item.gst_amount)})
                      after {dayjs(userData?.entrepreneur_monthly_plan_expiry_date).diff(dayjs(), "day")} days
                    </Button>
                  </>
                ) : (
                  <>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body1" fontWeight="bold">Validity:</Typography>
                      <Typography variant="body1" color="textSecondary">1 Month</Typography>
                    </Box>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleButtonClick(item.payment_reference_id || item._id)}
                      sx={{ mt: 2, width: "100%" }}
                    >
                      Pay Now {new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(item.monthly_amount + item.gst_amount)}
                    </Button>
                  </>
                )}
              </CardContent>


            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
