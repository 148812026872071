import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Store/validate";
import { GetMain, GetUser, UserContactUs } from "../../Api/Api";
import { useSelector } from "react-redux";

export default function ContactSection() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [contactDetail, setContactDetails] = useState({});

  const [contactValidation, setContactValidation] = useState({});

  const setContactDetailsValue = (key, value) => {
    setContactDetails({ ...contactDetail, [key]: value });
    if (contactValidation[key]) delete contactValidation[key];
  };

  const setContactValidationValue = (key, value) => {
    setContactValidation({ ...contactValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };
  const state = useSelector((state) => state);
  const [userProfile, setUserProfile] = useState([]);
  useEffect(() => {
    if (state?.auth?.isLoggedIn === true) {
      getUserProfile();
    }
    GetMyTsks();
  }, []);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if(state?.auth?.isLoggedIn){

    //     const Payload = {
    //       emailId: userProfile.email,
    //       name: userProfile.name,
    //       subject: " ",
    //       question: " ",
    //       mobileNumber: userProfile.phoneNumber,
    //     };
    //     const response = await UserContactUs(Payload);
    //     if (response && response.statusCode === 200) {
    //       showToastSuccessMsg(response.message);

    //     } else {
    //       showToastErrorMsg(
    //         response.message || response.error.response.data.message
    //       );
    //     }

    // }else{
    let validate = {};
    validate.name = StringValidation(contactDetail?.name);
    validate.emailId = EmailValidation(contactDetail?.emailId?.toLowerCase());
    validate.subject = NonEmptyValidation(contactDetail?.subject);
    validate.question = NonEmptyValidation(contactDetail?.question);
    validate.mobileNumber = PhoneNumberValidation(contactDetail?.mobileNumber);
    validate.servicesType = NonEmptyValidation(contactDetail?.servicesType);

    setContactValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: contactDetail.emailId,
        name: contactDetail.name,
        subject: contactDetail.subject,
        question: contactDetail.question,
        mobileNumber: contactDetail.mobileNumber,
        servicesType: contactDetail.servicesType,
      };
      const response = await UserContactUs(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        window.location.reload();

        setContactDetails({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
    // }
  };
  const [main, setMain] = useState([]);
  const GetMyTsks = async () => {
    const token = state.auth.auth.token;
    let data = await GetMain("active");
    if (data && data.statusCode === 200) {
      const numAscending = [...data.data].sort((a, b) => b.title < a.title);
      setMain(numAscending);
    } else {
      setMain([]);
      //console.log("Error while getting my task");
    }
  };
  //console.log(main);
  return (
    <div>
      <ToastContainer />
      {/* <div class="breadcumb-wrapper" data-bg-src="img/bg/breadcumb-bg.jpg">
        <div class="container">
            <div class="breadcumb-content">
                <h1 class="breadcumb-title">Contact Us</h1>
                <ul class="breadcumb-menu">
                    <li><a href="index.html">Home</a></li>
                    <li>Contact Us</li>
                </ul>
            </div>
        </div>
      </div> */}
      <div class="space">
        <div class="container">
          <div class="row gy-4">
            <div class="col-xl-4 col-md-6">
              <div class="contact-info">
                <div class="contact-info_icon"><i class="fas fa-location-dot"></i></div>
                <div class="media-body">
                  <h4 class="box-title">Our Office Address</h4>
                  <span class="contact-info_text">T Nagar, Chennai, Tamil Nadu, 600017</span>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-6">
              <div class="contact-info">
                <div class="contact-info_icon"><i class="fas fa-phone"></i></div>
                <div class="media-body">
                  <h4 class="box-title">Call Us Anytime</h4>
                  <span class="contact-info_text">
                    <a href="tel:+18003098445">1800-309-8445</a>
                    {/* <a href="tel:+65485965789">+65-48596-5789</a> */}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-6">
              <div class="contact-info">
                <div class="contact-info_icon"><i class="fas fa-envelope"></i></div>
                <div class="media-body">
                  <h4 class="box-title">Send An Email</h4>
                  <span class="contact-info_text">
                    <a href="mailto:info@dalmaf.com">info@dalmaf.com</a>
                    {/* <a href="mailto:info@webteck.com">info@webteck.com</a> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-smoke space" data-bg-src="img/bg/contact_bg_1.png" id="contact-sec">
        <div class="container">
          <div class="row">
            <div class="col-xl-8">
              <div class="title-area mb-35 text-xl-start text-center"><span class="sub-title">
                <div class="icon-masking me-2"><span class="mask-icon"
                  data-mask-src="img/theme-img/title_shape_2.svg"></span> <img
                    src="img/theme-img/title_shape_2.svg" alt="shape" /></div>contact with us!
              </span>
                <h2 class="sec-title">Have Any Questions?</h2>
                <p class="sec-text">Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going a new normal that from generation.</p>
              </div>
              <div className="contact-form row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        contactValidation?.name?.status === false
                      )}
                      value={contactDetail?.name}
                      onChange={(e) => {
                        setContactDetailsValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.name?.message
                        ? `Name ${contactValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control"
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        contactValidation?.mobileNumber?.status === false
                      )}
                      value={contactDetail?.mobileNumber}
                      onChange={(e) => {
                        setContactDetailsValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.mobileNumber?.message
                        ? `Mobile Number ${contactValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        contactValidation?.emailId?.status === false
                      )}
                      value={contactDetail?.emailId}
                      onChange={(e) => {
                        setContactDetailsValue("emailId", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.emailId?.message
                        ? `Email id ${contactValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Subject "
                      aria-invalid={Boolean(
                        contactValidation?.subject?.status === false
                      )}
                      value={contactDetail?.subject}
                      onChange={(e) => {
                        setContactDetailsValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.subject?.message
                        ? `Subject ${contactValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={contactDetail?.servicesType}
                      onChange={(e) => {
                        setContactDetailsValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="IT Solutions">IT Solutions </option>
                      {/* <option value="Buy/Sell">Buy/Sell</option> */}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Questions"
                      aria-invalid={Boolean(
                        contactValidation?.question?.status === false
                      )}
                      defaultValue={contactDetail?.question}
                      onChange={(e) => {
                        setContactDetailsValue("question", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.question?.message
                        ? `Question ${contactValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button class="th-btn" onClick={(e) => {
                      handleSubmit(e);
                    }}>
                    Send Message
                    <i class="fa-regular fa-arrow-right ms-2"></i>
                  </button>

                  {/* <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button> */}
                </div>
              </div>
              {/* <form action="mail.php" method="POST" class="contact-form ajax-contact">
                <div class="row">
                  <div class="form-group col-md-6"><input type="text" class="form-control" name="name"
                    id="name" placeholder="Your Name" /> <i class="fal fa-user"></i></div>
                  <div class="form-group col-md-6"><input type="email" class="form-control" name="email"
                    id="email" placeholder="Email Address" /> <i class="fal fa-envelope"></i></div>
                  <div class="form-group col-md-6"><select name="subject" id="subject" class="form-select">
                    <option value="" disabled="disabled" selected="selected" hidden>Select Subject
                    </option>
                    <option value="Web Development">Web Development</option>
                    <option value="Brand Marketing">Brand Marketing</option>
                    <option value="UI/UX Designing">UI/UX Designing</option>
                    <option value="Digital Marketing">Digital Marketing</option>
                  </select> <i class="fal fa-chevron-down"></i></div>
                  <div class="form-group col-md-6"><input type="tel" class="form-control" name="number"
                    id="number" placeholder="Phone Number" /> <i class="fal fa-phone"></i></div>
                  <div class="form-group col-12"><textarea name="message" id="message" cols="30" rows="3"
                    class="form-control" placeholder="Your Message"></textarea> <i
                      class="fal fa-comment"></i></div>
                  <div class="form-btn text-xl-start text-center col-12"><button class="th-btn">Send Message<i
                    class="fa-regular fa-arrow-right ms-2"></i></button></div>
                </div>
                <p class="form-messages mb-0 mt-3"></p>
              </form> */}
            </div>
          </div>
        </div>
      </div>
      <div class="map-sec">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
          allowfullscreen="" loading="lazy"></iframe>
      </div>
      {/* <div className=" about-banner2 py-5">
        <div className="container my-4">
          <h2 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h2>
          <div className="row">
            <div className="col-lg-6 my-auto ">
              <div className="row mb-4">
                <div className="col-lg-12 mb-4" data-aos="fade-in">
                  <h4 className="color-maroon fw-bold after-line mb-4">
                    Contact Us
                  </h4>
                  <p className="small">
                    Bring to the table win-win survival strategies to ensure
                    proactive domination. At the end of the day, going a new
                    normal that from generation
                  </p>
                </div>
                <div className="col-lg-12 col-md-6 mb-3" data-aos="fade-in">
                  <div className="counter-content2 text-start">
                    <div className="d-flex align-items-center">
                    <h2 className="banner-heading after-line mb-5">DALMAF Private Limited</h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 mb-3" data-aos="fade-in">
                  <div className="counter-content2 text-start">
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <h1 className="mb-0">
                          <span className="material-symbols-rounded align-middle about-call filled-icon">
                            call
                          </span>
                        </h1>
                      </div>
                      <div className="">
                        <p className=" color-maroon fw-bold mb-0">Toll Free</p>
                        <p className="mb-0 color-maroon fw-bold fs-5">
                          1800-309-8445
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 mb-3" data-aos="fade-in">
                  <div className="counter-content2 text-start">
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <h1 className="mb-0">
                          <span className="material-symbols-rounded align-middle about-call filled-icon">
                            mail
                          </span>
                        </h1>
                      </div>
                      <div className="">
                        <p className=" color-maroon fw-bold mb-0">Email ID</p>
                        <p className="mb-0 color-maroon fw-bold fs-5">
                          info@dalmaf.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-iframe">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                  width="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div
              className="col-lg-6 my-auto mx-0 client-left-section p-4"
              data-aos="fade-in"
            >
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <h4 className="color-maroon fw-bold after-line mb-4">
                    Have Any Questions
                  </h4>
                  <p className="small">Feel free to contact us through</p>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        contactValidation?.name?.status === false
                      )}
                      value={contactDetail?.name}
                      onChange={(e) => {
                        setContactDetailsValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.name?.message
                        ? `Name ${contactValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control"
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        contactValidation?.mobileNumber?.status === false
                      )}
                      value={contactDetail?.mobileNumber}
                      onChange={(e) => {
                        setContactDetailsValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.mobileNumber?.message
                        ? `Mobile Number ${contactValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        contactValidation?.emailId?.status === false
                      )}
                      value={contactDetail?.emailId}
                      onChange={(e) => {
                        setContactDetailsValue("emailId", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.emailId?.message
                        ? `Email id ${contactValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Subject "
                      aria-invalid={Boolean(
                        contactValidation?.subject?.status === false
                      )}
                      value={contactDetail?.subject}
                      onChange={(e) => {
                        setContactDetailsValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.subject?.message
                        ? `Subject ${contactValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={contactDetail?.servicesType}
                      onChange={(e) => {
                        setContactDetailsValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Questions"
                      aria-invalid={Boolean(
                        contactValidation?.question?.status === false
                      )}
                      defaultValue={contactDetail?.question}
                      onChange={(e) => {
                        setContactDetailsValue("question", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.question?.message
                        ? `Question ${contactValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
