
export const url =process.env.NODE_ENV === "development" ? "https://dalmaf.com/dalmaf" : "/dalmaf";

export const entrepreneur_service_url = process.env.NODE_ENV === "development" ? "https://dalmaf.com/entrepreneur-service/" : "https://dalmaf.com/entrepreneur-service/";
export const payment_service_url = process.env.NODE_ENV === "development" ? "https://dalmaf.com/payment-service/" : "https://dalmaf.com/payment-service/";
export const lead_service_url = process.env.NODE_ENV === "development" ? "https://dalmaf.com/lead-service/" : "https://dalmaf.com/lead-service/";


export const entrepreneur_ui_url = process.env.NODE_ENV === "development" ? "http://localhost:3001/business-portal" : "https://dalmaf.com/business-portal";

export const dalmaf_url = process.env.NODE_ENV === "development" ? "https://dalmaf.com" : "https://dalmaf.com";

