import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer/Footer";
import UserNavigation from '../Components/v1/NavBar/Navigaton';
import { createWithoutTokenAPI, fetchWithoutTokenAPI } from "../Api/v1/Util/Api";
import { schemes_fetch_all_url, schemes_find_particular_all_url, schemes_user_scheme_request_submitted_url } from "../Api/APIUrl";
import BestServices from "../Components/v1/Services/BestServices";
import ScheduleMeetBanner from "../Components/v1/Home/ScheduleMeetBanner";
import AppDownload from "../Components/v1/Home/AppDownload";
import FAQ from "../Components/v1/Home/FAQ";
import Newletter from '../Components/v1/Home/Newletter';
import { Modal, Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

const DalmafSchemes = () => {

  const state = useSelector((state) => state);
  useEffect(() => {
    window.scroll(0, 0);
    fetchSchemes();
  }, []);

  const [schemes, setSchemes] = useState({});
  const [schemesSpecific, setSchemesSpecific] = useState({});
  const [errors, setErrors] = useState({});
  const [activeSchemeId, setActiveSchemeId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [innerTabName, setInnerTabName] = useState(schemesSpecific?.key?.[0] || ""); // Default to the first tab

  // Form state
  const [formData, setFormData] = useState({
    scheme_id: "",
    name: "",
    phone_number: "",
    email_id: "",
  });

  const fetchSchemes = async () => {
    try {
      const response = await fetchWithoutTokenAPI(schemes_fetch_all_url);
      if (response && response.statusCode === 200) {
        const fetchedSchemes = response.data?.schemes || [];

        if (fetchedSchemes.length > 0) {
          const initialSchemeId = fetchedSchemes[0]._id;

          // Set the schemes and active scheme ID
          setSchemes(response.data);
          setActiveSchemeId(initialSchemeId);

          // Set form data with scheme_id, name, and email (if available)
          setFormData({
            ...formData, // Include any existing formData properties first
            scheme_id: initialSchemeId,
            name: state?.auth?.auth?.name || '',
            email_id: state?.auth?.auth?.email || '',
          });


          // Call filterLevel2 with the initial scheme ID
          filterLevel2(initialSchemeId);
        }
      }
    } catch (error) {
      console.error("Error fetching schemes:", error);
    }
  };

  const filterLevel2 = async (id) => {
    try {
      const response = await fetchWithoutTokenAPI(schemes_find_particular_all_url + '?_id=' + id);
      if (response && response.statusCode === 200) {
        setSchemesSpecific(response.data);
        setInnerTabName(response.data?.key?.[0])

      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSchemeClick = (id) => {
    filterLevel2(id);
    setFormData({ ...formData, scheme_id: id });
    setActiveSchemeId(id); // Update active scheme ID
  };
  const handleOpenModal = () => {
    setErrors({});
    setShowModal(true);
  };

  const handleInnerTabClick = (scheme) => {
    setInnerTabName(scheme);
  };
  // Handle form input change
  const handleChange = (e) => {
    setErrors({});
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // Validate form inputs
  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Name is required";
    if (!formData.phone_number || !/^\d{10}$/.test(formData.phone_number)) {
      formErrors.phone_number = "Valid phone number is required (10 digits)";
    }
    if (!formData.email_id || !/\S+@\S+\.\S+/.test(formData.email_id)) {
      formErrors.email_id = "Valid email is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submit
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        // Replace with your API endpoint
        const response = await createWithoutTokenAPI(schemes_user_scheme_request_submitted_url, formData);
        if (response && response.statusCode === 200) {
          toast.success("Consultation request submitted successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
            autoClose: 3000,
          });
          setFormData({})
          handleCloseModal(); // Close modal on success
        } else {
          toast.error("Failed to submit consultation request.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
            autoClose: 5000,
          });
          // alert("Failed to submit consultation request.");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="home-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>DALMaf Schemes | DALMaf Private Limited</title>
        <meta
          name="description"
          content="Join us at DALMaf Private Limited and explore the opportunities of partnering with us."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <UserNavigation />

      <div className="container mt-5">
        {/* <div className="row" style={{ margin: "0 auto" }}> */}

        {/* Schemes List */}
        <div className="d-flex flex-wrap justify-content-center mb-4">
          {schemes && schemes.schemes?.map((data, index) => (
            <div
              key={index}
              className={activeSchemeId === data._id ? "active-scheme" : ""}
              style={{
                cursor: "pointer",
                padding: "12px 20px",
                fontSize: "22px",
                fontWeight: "500",
                color: activeSchemeId === data._id ? "#dc3545" : "#333",
                borderBottom: activeSchemeId === data._id ? "3px solid #dc3545" : "3px solid transparent",
                transition: "color 0.3s ease, border-bottom 0.3s ease",
                textAlign: "center",
                margin: "0 15px",
                minWidth: "120px",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.color = "#dc3545";
              }}
              onMouseLeave={(e) => {
                if (activeSchemeId !== data._id) {
                  e.currentTarget.style.color = "#333";
                }
              }}
              onClick={() => handleSchemeClick(data._id)}
            >
              {data.title}
            </div>
          ))}
        </div>


        {/* Scheme Details */}
        {/* <div className="col-md-9 col-lg-9 col-sm-12" style={{
            // backgroundColor: "#f8f9fa",
            padding: "20px",
            // borderRadius: "10px",
            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}> */}
        <div className="scheme-details">
          <h5 className="mb-4" style={{ fontWeight: "600", color: "#333" }}>Services Covered</h5>
          <div className="row">
            {/* {schemesSpecific.sub_levels?.map((item, index) => (
              <div key={index} className="col-md-4 col-lg-4 col-sm-6">
                {
                  item?.hover_content?.length ?
                    <OverlayTrigger
                      trigger="hover"
                      placement="top"
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <ol>
                              {item.hover_content?.map((content, i) => (
                                <li key={i}>{content}</li>
                              ))}
                            </ol>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div
                        key={index}
                        style={{
                          // alignItems: "center",
                          // textAlign: "left",
                          // transition: "none", // Disable transition effects
                          // boxShadow: "none",  // Neutralize any hover box-shadow changes
                          // cursor: "default",  // Remove pointer effect on hover
                          padding: "15px",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          backgroundColor: "#f8f9fa",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          marginBottom: "20px",
                          minHeight: "80px",
                          maxHeight: "80px",
                          overflow: "auto",
                          scrollbarWidth: "thin", // Firefox: Makes the scrollbar smaller
                          scrollbarColor: "#ccc transparent",
                        }}
                      // className="card h-75 shadow service-card no-hover"
                      >
                        <i className="fa fa-check-circle" style={{ color: "#28a745", fontSize: "18px" }}></i>
                        {item.title}
                      </div>
                    </OverlayTrigger> :
                    <div
                      key={index}
                      style={{
                        // alignItems: "center",
                        // textAlign: "left",
                        // transition: "none", // Disable transition effects
                        // boxShadow: "none",  // Neutralize any hover box-shadow changes
                        // cursor: "default",  // Remove pointer effect on hover
                        padding: "15px",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        backgroundColor: "#f8f9fa",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        marginBottom: "20px",
                        minHeight: "80px",
                        maxHeight: "200px",
                        overflow: "auto",
                        scrollbarWidth: "thin", // Firefox: Makes the scrollbar smaller
                        scrollbarColor: "#ccc transparent",
                      }}
                    // className="card h-75 shadow service-card no-hover"
                    >
                      <i className="fa fa-check-circle" style={{ color: "#28a745", fontSize: "18px" }}></i>
                      {item.title}
                    </div>
                }

              </div>
            ))} */}
            {schemesSpecific?.split && schemesSpecific?.key?.length > 0 ? (
              <>
                <div style={{ display: "flex", borderBottom: "2px solid #ddd", overflowX: "auto", paddingBottom: "10px" }}>
                  {schemesSpecific?.key?.map((data, index) => (
                    <div
                      key={index}
                      className={innerTabName === data ? "active-scheme" : ""}
                      style={{
                        cursor: "pointer",
                        padding: "12px 20px",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: innerTabName === data ? "#dc3545" : "#333",
                        borderBottom: innerTabName === data ? "3px solid #dc3545" : "3px solid transparent",
                        transition: "color 0.3s ease, border-bottom 0.3s ease",
                        textAlign: "center",
                        margin: "0 15px",
                        minWidth: "120px",
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.color = "#dc3545")}
                      onMouseLeave={(e) => {
                        if (innerTabName !== data) e.currentTarget.style.color = "#333";
                      }}
                      onClick={() => handleInnerTabClick(data)}
                    >
                      {data}
                    </div>
                  ))}
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                  {schemesSpecific?.sub_levels
                    ?.filter((item) => item.badge === innerTabName) // Filtering items based on innerTabName
                    ?.map((item, index) => (
                      <div key={index} className="col-md-4 col-lg-4 col-sm-6">
                        {item?.hover_content?.length ? (
                          <OverlayTrigger
                            trigger="hover"
                            placement="top"
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  <ol style={{ textAlign: "left", margin: 0, paddingLeft: "20px" }}>
                                    {item.hover_content?.map((content, i) => (
                                      <li key={i} style={{ textAlign: "left", marginBottom: "5px" }}>
                                        {content}
                                      </li>
                                    ))}
                                  </ol>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <div
                              style={{
                                padding: "15px",
                                borderRadius: "8px",
                                display: "flex",
                                alignItems: "flex-start",
                                gap: "10px",
                                backgroundColor: "#f8f9fa",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                marginBottom: "20px",
                                minHeight: "80px",
                                overflow: "auto",
                                scrollbarWidth: "thin",
                                scrollbarColor: "#ccc transparent",
                              }}
                            >
                              <i
                                className="fa fa-check-circle"
                                style={{
                                  color: "#28a745",
                                  fontSize: "18px",
                                  marginRight: "10px",
                                  alignSelf: "center",
                                }}
                              ></i>
                              <span style={{ textAlign: "left", flex: 1 }}>{item.title}</span>
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <div
                            style={{
                              padding: "15px",
                              borderRadius: "8px",
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              backgroundColor: "#f8f9fa",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              marginBottom: "20px",
                              minHeight: "80px",
                              overflow: "auto",
                              scrollbarWidth: "thin",
                              scrollbarColor: "#ccc transparent",
                            }}
                          >
                            <i className="fa fa-check-circle" style={{ color: "#28a745", fontSize: "18px" }}></i>
                            <span style={{ textAlign: "left", flex: 1 }}>{item.title}</span>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <div className="row">
                {schemesSpecific.sub_levels?.map((item, index) => (
                  <div key={index} className="col-md-4 col-lg-4 col-sm-6">
                    {item?.hover_content?.length ? (
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={
                          <Popover>
                            <Popover.Body>
                              <ol style={{ textAlign: "left", margin: 0, paddingLeft: "20px" }}>
                                {item.hover_content?.map((content, i) => (
                                  <li key={i} style={{ textAlign: "left", marginBottom: "5px" }}>
                                    {content}
                                  </li>
                                ))}
                              </ol>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <div
                          style={{
                            padding: "15px",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "10px",
                            backgroundColor: "#f8f9fa",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            marginBottom: "20px",
                            minHeight: "80px",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                            scrollbarColor: "#ccc transparent",
                          }}
                        >
                          <i
                            className="fa fa-check-circle"
                            style={{
                              color: "#28a745",
                              fontSize: "18px",
                              marginRight: "10px",
                              alignSelf: "center",
                            }}
                          ></i>
                          <span style={{ textAlign: "left", flex: 1 }}>{item.title}</span>
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <div
                        style={{
                          padding: "15px",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          backgroundColor: "#f8f9fa",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          marginBottom: "20px",
                          minHeight: "80px",
                          overflow: "auto",
                          scrollbarWidth: "thin",
                          scrollbarColor: "#ccc transparent",
                        }}
                      >
                        <i
                          className="fa fa-check-circle"
                          style={{ color: "#28a745", fontSize: "18px" }}
                        ></i>
                        <span style={{ textAlign: "left", flex: 1 }}>{item.title}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* Know More Button */}
            <div className="col-12 text-center mt-4">
              <button
                onClick={handleOpenModal}
                className="th-btn shadow-none">
                {/* Book Your Free Consultation Today */}
                Pricing Details
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Free Consultation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                isInvalid={!!errors.phone_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone_number}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email_id"
                value={formData.email_id}
                onChange={handleChange}
                isInvalid={!!errors.email_id}
              />
              <Form.Control.Feedback type="invalid">{errors.email_id}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleCloseModal}
            className="btn">
            Close
          </button>
          <button
            onClick={handleSubmit}
            className="th-btn shadow-none">
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      <BestServices />
      {/* <AboutUs/> */}
      {/* <AboutExperince/> */}
      <ScheduleMeetBanner />
      {/* <Testimonial/> */}
      <AppDownload />
      <Newletter />
      <FAQ />

      <Footer />

    </div>
  );
};

export default DalmafSchemes;
