import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import AdminSideListComponent from "./AdminSideListComponent";
import { getSubscribersServiceRequest } from "../../../Api/v1/leads/Api";

export default function SubscribersRequestListComponent({ statusTypeProps, labelProps }) {
  const [data, setData] = useState([]);
  const { auth } = useSelector((state) => state);
  const token = auth?.auth?.token;
  const role = auth?.auth?.role;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setData([]); // Clear existing data before fetching new
        const response = await getSubscribersServiceRequest(token, statusTypeProps);
        if (response?.statusCode === 200 && response?.data?.scheme_request) {
          setData(response.data.scheme_request);
        }
      } catch (error) {
        toast.error("Failed to fetch data.");
      }
    };

    fetchData();
  }, [statusTypeProps, token]); // Ensures re-fetching when status changes

  // Memoized theme to avoid unnecessary re-renders
  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiTableCell: {
            styleOverrides: {
              head: {
                whiteSpace: "nowrap",
                textAlign: "center",
                fontWeight: "bold",
                backgroundColor: "#f5f5f5",
                fontSize: "14px",
              },
              body: {
                textAlign: "center",
                fontSize: "14px",
              },
            },
          },
        },
      }),
    []
  );

  return (
    <div style={{ padding: "20px" }}>
      <ThemeProvider theme={theme}>
        {/* Conditional Rendering for different roles */}
        {role === "superadmin" || role === "admin" || role ==="subadmin" || role ==="user" ? (
          <AdminSideListComponent statusTypeProps={statusTypeProps} labelProps={labelProps} />
        ) : null 
        /* Add other role-based components when needed */}
      </ThemeProvider>
      <ToastContainer />
    </div>
  );
}