import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AdminNavbar from "../../../Admin/Component/Navbar/AdminNavbar";
import SubscribersRequestListComponent from "../../../Components/v1/SubscribersRequest/SubscribersRequestListComponent";
import { useSelector } from "react-redux";

export default function SubscribersList() {
  const [value, setValue] = React.useState('all');
  const state = useSelector((state) => state); // Assuming Redux is being used
  const userRole = state?.auth?.auth?.role;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const allFilters = [
    { key: "all", show: "All" },
    { key: "unassigned", show: "Unassigned" },
    { key: "assigned", show: "Assigned" },
    { key: "inprogres", show: "In Progress" },
    { key: "hold", show: "Hold" },
    { key: "completed", show: "Completed" }
  ];

  // Hide "Unassigned" filter for "subadmin"
  const filteredFilters = userRole === "subadmin"
    ? allFilters.filter(item => item.key !== "unassigned")
    : allFilters;

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Schedule Meeting Details"
          TabIndicatorProps={{
            style: { backgroundColor: "#dc3545" },
          }}
          textColor="inherit"
        >
          {filteredFilters.map((item) => (
            <Tab
              key={item.key}
              label={item.show}
              value={item.key}
              sx={{
                color: value === item.key ? "#dc3545" : "#000",
                fontWeight: value === item.key ? "bold" : "normal",
                backgroundColor: value === item.key ? "#fff2f2" : "transparent",
                borderRadius: "5px",
                padding: "10px 20px",
                transition: "0.3s ease",
                "&:hover": {
                  color: "#dc3545",
                  backgroundColor: "#f9f9f9",
                },
              }}
            />
          ))}
        </Tabs>

        <SubscribersRequestListComponent
          statusTypeProps={value}
          labelProps={filteredFilters.find((item) => item.key === value)?.show}
        />
      </div>
    </div>
  );
}