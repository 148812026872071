import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AdminNavbar from "../../../Admin/Component/Navbar/AdminNavbar";
import SchemesRequestListComponent from "../../../Components/v1/Schemes/SchemesRequestListComponent";
import PlansRequestListComponent from "../../../Components/v1/Schemes/PlansRequestListComponent";

export default function MyBAPlansList() {
  const [value, setValue] = React.useState('active');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const filter = [
    { key: "active", show: "Active" },
    { key: "pending", show: "Yet to Pay" },
    { key: "inactive", show: "Expired" },
  ];
  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main" style={{marginTop:'20px'}}>
        {/* <div className="main"> */}
          <PlansRequestListComponent/>
        </div>
    </div>
  );
}