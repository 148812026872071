import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import { EmailValidation, PhoneNumberValidation, StringValidation } from '../Store/validate';
import { showToastErrorMsg, showToastSuccessMsg } from '../Store/toastMsg';
import { UserServiceRequest } from '../Api/Api';
import { ToastContainer } from 'react-toastify';
import Navigation from '../Components/v1/NavBar/Navigaton';
import { fetchWithoutTokenAPI } from '../Api/v1/Util/Api';
import Select from "react-select";

export default function FreeLegalCamp() {
  useEffect(() => {
    window.scroll(0, 0)
    fetchService();
  }, [])


  const [serviceReqValidation1, setServiceReqValidation1] = useState({});
  const [serviceReq1, setServiceReq1] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [form, setForm] = useState(false);
  const [dropDownData, setDropDownData] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };


  const setServiceReqValue1 = (key, value) => {
    setServiceReq1({ ...serviceReq1, [key]: value });
    if (serviceReqValidation1[key]) delete serviceReqValidation1[key];
  };
  const setServiceReqValidationValue1 = (key, value) => {
    setServiceReqValidation1({ ...serviceReqValidation1, [key]: value });
  };

  const handleSubmitServiceform = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setServiceReq1({ ...serviceReq1, subject: 'Free Legal Camp' });
    let Payload;
    Payload = {
      name: serviceReq1.name,
      email: serviceReq1.email,
      // subject: serviceReq1.subject || '',  // Ensure subject is an empty string if null or undefined
      // question: serviceReq1.question || '',  // Ensure question is an empty string if null or undefined
      mobileNumber: serviceReq1.mobileNumber,
      type: "service",
      // servicesCategory: 'Legal',
      // servicesSubcategory: 'Banking & Financial Issues',
      servicesTitle: selectedOptions.toString(),
    };
    const response = await UserServiceRequest(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setServiceReq1({});
      setForm(false);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
    setIsButtonDisabled(false);
  };

  const fetchService = async () => {
    try {
      const response = await fetchWithoutTokenAPI("/findServicesDetails?status=active&masterType=subServiceL2&mainServiceId=67c8651cc6833c8c4c561103&subServiceL1Id=67c863b1c6833c8c4c561101");
      if (response && response.statusCode === 200) {
        setDropDownData(response?.data)
      }
    } catch (error) {
      console.error("Error fetching schemes:", error);
    }
  };


  return (
    <div className="innerpages-nav" style={{ height: "1100px" }}>
      <ToastContainer />
      <Helmet>
        <meta charSet="utf-8" />
        <title>DALMaf Service Listing | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="We are happy to Introduce the Dalmaf Company, the one-stop destination for Legal, Auditing, Bank Auction Support, Buying and Selling, and Marketing Assistance. Founded in June of 2022 as a Global Market Pioneer for offering comprehensive company solutions in all aspects, Our team of Professionals is Passionate about aiding businesses in Resolving Difficulties, Strengthening their Internal Operations, and Realising their ultimate goal." />
        <meta name="keywords" content="We are happy to Introduce the Dalmaf Company, the one-stop destination for Legal, Auditing, Bank Auction Support, Buying and Selling, and Marketing Assistance. Founded in June of 2022 as a Global Market Pioneer for offering comprehensive company solutions in all aspects, Our team of Professionals is Passionate about aiding businesses in Resolving Difficulties, Strengthening their Internal Operations, and Realising their ultimate goal." />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div id="container">
        <Navigation />
        <div style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1513530534585-c7b1394c6d51?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cHJvZmVzc2lvbmFsJTIwYmFja2dyb3VuZHxlbnwwfHwwfHx8MA%3D%3D")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          marginTop: '-25px',
          height: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div className="container py-5" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-lg-8 col-md-8 mb-4 d-flex justify-content-center align-items-center">
                <div style={{ width: "80%" }}>
                  <h1>Are You Looking for Legal Solutions?</h1>
                  <p style={{ fontSize: "18px" }}>
                    Join our <strong>Free Legal Camp</strong> and get expert assistance on various legal matters.
                    Dalmaf Private Limited is here to guide you towards the right legal solutions.
                  </p>
                  <ul style={{ fontSize: "16px",textAlign: "left", listStyleType: "none", padding: 0 }}>
                    <li>📅 Date: March 10th - 14th (Monday to Friday)</li>
                    <li>⏰ Time: 9:00 AM – 1:00 PM</li>
                    <li>🏢 Hosted by: Dalmaf Private Limited</li>
                    <li>🔹 Your Trusted Partner for Comprehensive Legal Solutions</li>
                  </ul>


                </div>
              </div>

              <div className="col-lg-4 col-md-4">
                {/* <div className="request-callback-form sticky-top p-4 border rounded shadow"> */}
                <div className="request-callback-form p-4 border rounded shadow" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                  <h5>REQUEST A CALL BACK</h5>
                  <form onSubmit={handleSubmitServiceform}>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Name"
                        aria-invalid={Boolean(serviceReqValidation1?.name?.status === false)}
                        value={serviceReq1?.name || ''}
                        onChange={(e) => setServiceReqValue1("name", e.target.value)}
                        onBlur={(e) => setServiceReqValidationValue1("name", StringValidation(e.target.value))}
                        required
                      />

                      <small className="text-danger">
                        {serviceReqValidation1?.name?.message ? `Name ${serviceReqValidation1?.name?.message}` : ""}
                      </small>
                    </div>
                    <div className="mb-3">
                      <input
                        type="tel"
                        maxLength={10}
                        className="form-control"
                        placeholder="Enter Your Mobile No"
                        aria-invalid={Boolean(serviceReqValidation1?.mobileNumber?.status === false)}
                        value={serviceReq1?.mobileNumber || ''}
                        onChange={(e) => setServiceReqValue1("mobileNumber", e.target.value)}
                        onBlur={(e) => setServiceReqValidationValue1("mobileNumber", PhoneNumberValidation(e.target.value))}
                        required
                      />
                      <small className="text-danger">
                        {serviceReqValidation1?.mobileNumber?.message ? `Mobile no ${serviceReqValidation1?.mobileNumber?.message}` : ""}
                      </small>
                    </div>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your E-Mail ID"
                        aria-invalid={Boolean(serviceReqValidation1?.email?.status === false)}
                        value={serviceReq1?.email?.toLowerCase() || ''}
                        onChange={(e) => setServiceReqValue1("email", e.target.value.toLowerCase())}
                        onBlur={(e) => setServiceReqValidationValue1("email", EmailValidation(e.target.value.toLowerCase()))}
                        required
                      />
                      <small className="text-danger">
                        {serviceReqValidation1?.email?.message ? `Email ${serviceReqValidation1?.email?.message}` : ""}
                      </small>
                    </div>
                    <div className="mb-3">
                      <textarea
                        rows="2"
                        className="form-control"
                        placeholder="Enter Your Comments/Needs"
                        value={'Free Legal Camp'}
                        onChange={(e) => setServiceReqValue1("subject", e.target.value)}
                        required
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <select onChange={handleChange}>
                        {dropDownData?.map((data) => (
                          <option key={data.title} value={data.title}>{data.title}</option>
                        ))}
                      </select>
                      {/* <Select
                        options={dropDownData}
                        // isMulti
                        getOptionLabel={(e) => e.title}
                        getOptionValue={(e) => e.title}
                        onChange={handleChange}
                      /> */}

                    </div>


                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isButtonDisabled}
                      >
                        {/* Get Started */}
                        {isButtonDisabled ? (
                          <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Submiting...
                          </>
                        ) : (
                          'Get Started'
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
