import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AdminNavbar from "../../../Admin/Component/Navbar/AdminNavbar";
import SchemesRequestListComponent from "../../../Components/v1/Schemes/SchemesRequestListComponent";
import SpecificRequestComponent from "../../../Components/v1//SubscribersRequest/SpecificRequestComponent";

export default function ViewSpecificSubscribersRequest() {
  const [value, setValue] = React.useState('all');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
 
  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <Box>
        

        <div className="main" style={{ marginTop: "50px" }}>
          <SpecificRequestComponent/>
        </div>
      </Box>

    </div>
  );
}