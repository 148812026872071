import axios from "axios";
import { entrepreneur_service_url, url, lead_service_url } from "../../BaseUrl";
import {subscribers_service_request_find_all_url, subscribers_service_request_find_particular_url,subscribers_service_request_comments_find_all,subscribers_service_request_comments_create, subscribers_service_request_update  }  from "../../APIUrl"

const APIBaseUrl = url;

//------------------- Freelauncer Start -------------------//

export const freelauncerSubmit = async (Payload) => {
    const response = await axios
        .post(`${entrepreneur_service_url}v1/entrepreneur/create`, Payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const fetchFreelauncer = async (token, status) => {
    const response = await axios
        .get(`${APIBaseUrl}/v1/freelancers/find?status=${status}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};


export const updateFreelauncerStatus = async (Payload, token) => {
    const response = await axios
        .put(`${APIBaseUrl}/v1/freelancers/update`, Payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

//------------------- Freelauncer Ends -------------------//


//------------------- Schedule A meet Start -------------------//

export const ScheduleMeet = async (Payload) => {
    const response = await axios
        .post(`${APIBaseUrl}/v1/schedule_meeting/submit`, Payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

//------------------- Schedule A meet Ends -------------------//

//------------------- Partner Start -------------------//

export const partnerSubmit = async (Payload) => {
    const response = await axios
        .post(`${APIBaseUrl}/partners/submit`, Payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const fetchPartner = async (token, status) => {
    const response = await axios
        .get(`${APIBaseUrl}/partners/find?status=${status}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const updatePartnerStatus = async (Payload, token) => {
    const response = await axios
        .put(`${APIBaseUrl}/partners/update`, Payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const getSubscribersServiceRequest = async (token, status) => {
    const response = await axios
        .get(`${lead_service_url}${subscribers_service_request_find_all_url}?scheme_status=${status}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const getSpecificSubscribersServiceRequest = async (token, id) => {
    const response = await axios
        .get(`${lead_service_url}${subscribers_service_request_find_particular_url}?_id=${id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const updateSubscribersServiceRequest = async (Payload, token) => {
    const response = await axios
        .put(`${lead_service_url}${subscribers_service_request_update}`, Payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const SubscribersRequestCommentsCreate = async (token, Payload) => {
    const response = await axios
        .post(`${lead_service_url}${subscribers_service_request_comments_create}`, Payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token

            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const getSpecificSubscribersServiceRequestComments = async (token, id) => {
    const response = await axios
        .get(`${lead_service_url}${subscribers_service_request_comments_find_all}?reference_id=${id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};


//------------------- Partner Ends -------------------//